import React from "react"

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"
import ContactForm from "../components/forms/ContactForm"

const ContactPage = () => (
  <MainContainer>
    <HeadData title='Get In Touch' />

    <div className='w-full bg-ff-blue-2 pt-12 pb-12 md:pt-24'>
      <h1 className="text-ff-blue-9 text-5xl md:text-6xl text-center font-bold uppercase leading-tight mb-4">Get In Touch</h1>

      <a href='tel:253-507-4633' className="block text-ff-blue-8 text-3xl md:text-4xl text-center font-bold mb-12">
        253-507-4633
      </a>

      <ContactForm />
    </div>
  </MainContainer>
)

export default ContactPage